import { ReactComponent as DropDownArrow } from '@material-design-icons/svg/round/expand_more.svg';
import clsx from 'clsx';
import { FC, SVGProps, useRef, useState } from 'react';
import { AriaMenuProps, Placement, useMenuTrigger } from 'react-aria';
import { MenuTriggerProps, useMenuTriggerState } from 'react-stately';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';

import Menu from './Menu';
import * as S from './styles';

interface Props<T> extends AriaMenuProps<T>, MenuTriggerProps {
  'aria-label'?: string;
  className?: string;
  customWidth?: number;
  'data-cy'?: string;
  hideIconGap?: boolean;
  label?: string;
  matchButtonWidth?: boolean;
  placement?: Placement;
  trailingIcon?: FC<SVGProps<SVGSVGElement>>;
  variant?: 'empty' | 'outline' | 'plain' | 'primary';
}

const ButtonWithMenu = ({
  'aria-label': ariaLabel,
  className,
  customWidth,
  'data-cy': dataCy,
  hideIconGap = false,
  label,
  matchButtonWidth = false,
  placement = 'bottom start',
  trailingIcon,
  variant = 'primary',
  ...ariaMenuProps
}: Props<object>) => {
  const state = useMenuTriggerState(ariaMenuProps);
  const ref = useRef<HTMLButtonElement>(null);
  const { menuProps, menuTriggerProps } = useMenuTrigger<object>({}, state, ref);

  const [size, setSize] = useState<Size>({
    height: undefined,
    width: undefined
  });

  const onResize = useDebounceCallback(setSize, 200);

  useResizeObserver({
    box: 'border-box',
    onResize,
    ref
  });

  return (
    <>
      <S.MenuButton
        aria-label={ariaLabel}
        className={clsx({ 'no-gap': hideIconGap }, className)}
        data-cy={dataCy}
        {...menuTriggerProps}
        ref={ref}
        trailingIcon={trailingIcon ?? DropDownArrow}
        variant={variant}
      >
        {label}
      </S.MenuButton>
      {state.isOpen && (
        <S.MenuPopover
          $width={
            matchButtonWidth ? size.width : customWidth !== undefined ? customWidth : undefined
          }
          placement={placement}
          state={state}
          triggerRef={ref}
        >
          <Menu
            data-cy="button-with-menu-popover"
            {...ariaMenuProps}
            {...menuProps}
          />
        </S.MenuPopover>
      )}
    </>
  );
};

export default ButtonWithMenu;
