import { LocationFilter } from 'src/pages/Dashboard/hooks/useGetNetworkLocations';

export const prepareLocationsParam = (
  locations: AtLeastOne<LocationFilter>[] | string | undefined
): string | undefined => {
  if (locations === undefined) return undefined;
  const locationsIsArray = Array.isArray(locations);
  const moreThanOneLocation = locationsIsArray && Array.from(locations).length > 0;
  return Array.isArray(locations)
    ? moreThanOneLocation
      ? JSON.stringify(locations)
      : undefined
    : locations;
};
