import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ScreenSizeWarning from 'src/components/ScreenSizeWarning';
import SecondaryNavigation from 'src/components/SecondaryNavigation';
import ToastProvider from 'src/components/ToastProvider';
import useBreakpointRange from 'src/hooks/useBreakpointRange';
import useUser from 'src/hooks/useUser';

import * as S from './styles';

const INCLUSIVITY_PARAMS =
  'order_by_locations%5Bcolumn%5D=provider_count&order_by_locations%5Bdir%5D=desc&order_by_providers%5Bcolumn%5D=full_name&order_by_providers%5Bdir%5D=asc&order_by_organizations%5Bcolumn%5D=on_violet&order_by_organizations%5Bdir%5D=desc';

const NETWORK_INCLUSIVITY_BASE_URL = '/dashboard/my-network/inclusivity';

export const NETWORK_INCLUSIVITY_URL_W_PARAMS = `${NETWORK_INCLUSIVITY_BASE_URL}?${INCLUSIVITY_PARAMS}`;

const MyNetworkWrapper = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const hasServiceAreasEnabled = useFeatureFlagEnabled('network_granular_geography');

  useEffect(() => {
    if (!user.member_roles.includes('network_manager')) {
      /* user does not have permission to access network data */
      navigate('/dashboard', { replace: true });
    }
  }, [user.member_roles, navigate]);

  const { isInMobileBreakpointRange } = useBreakpointRange();

  if (isInMobileBreakpointRange) {
    return <ScreenSizeWarning />;
  }

  return (
    <ToastProvider placement="top">
      {toastState => (
        <>
          <SecondaryNavigation
            linkList={[
              { exact: true, link: '/dashboard/my-network', title: 'Overview' },
              {
                children: [
                  {
                    exact: true,
                    link: `?${INCLUSIVITY_PARAMS}`,
                    title: 'Overview'
                  },
                  {
                    hideCondition: hasServiceAreasEnabled !== true,
                    link: `/service-areas?order_by%5Bcolumn%5D=provider_count&order_by%5Bdir%5D=desc&page=1&view=county`,
                    title: 'Service areas'
                  },
                  {
                    link: `/diversity`,
                    title: 'Diversity'
                  }
                ],
                link: NETWORK_INCLUSIVITY_URL_W_PARAMS,
                parentLink: NETWORK_INCLUSIVITY_BASE_URL,
                title: 'Inclusivity'
              },
              {
                link: `/dashboard/my-network/education-engagement?order_by%5Bcolumn%5D=user_name&order_by%5Bdir%5D=asc&resource_type=course_collection`,
                title: 'Education engagement'
              }
            ]}
            navTitle="My network"
          />
          <S.Main data-cy="network-page">
            <Outlet context={toastState} />
          </S.Main>
        </>
      )}
    </ToastProvider>
  );
};

export default MyNetworkWrapper;
