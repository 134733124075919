import { Temporal } from '@js-temporal/polyfill';
import uniqBy from 'lodash/uniqBy';
import { camelizeObjectKeys } from 'src/utils/camelCaseObject';

import notYetAwardedBadgeImageSrc from './images/not-yet-awarded-badge.svg';

export interface AssignmentUser {
  assignmentStatus: 'complete' | 'in_progress' | 'not_started';
  completed: boolean;
  completedAt?: Temporal.PlainDate | Temporal.PlainDateLike | string;
  email: string;
  fullName: string;
  id: string;
  isClinical: boolean;
  status: 'activated' | 'created' | 'deactivated';
}

export interface Benchmark {
  earnedAt: string;
  id: string;
  image: {
    url: string;
  };
  scoreLevel: string;
  scoreType: string;
}

interface AccreditingOrganizations {
  professionalGroup: string;
  professionalGroupAcronym: string;
}

export interface Collection {
  accreditingOrganizations: AccreditingOrganizations[];
  bookmarks: Bookmark[];
  canBeEvaluated: boolean;
  certificates: Certificate[];
  communities: string[];
  completedAt?: string | null;
  completionCount: number;
  courses: EducationalResource[];
  credits: string;
  description: string;
  duration: number;
  evaluation: CourseCollection['evaluation'];
  externalAccreditationReferences: {
    accrediting_organizations?: {
      professional_group: string;
      professional_group_acronym: string;
    }[];
    vendor_name: string;
    vendor_resource_id: string;
  }[];
  hours: string | null;
  id: string;
  isAccredited: boolean;
  isNew: boolean;
  isPopular: boolean;
  level?: 'advanced' | 'beginner' | 'foundation' | 'intermediate';
  progress: number;
  published: 'not_published' | 'pinned' | 'published';
  specialties: ('mental_health' | 'physical_health')[];
  startedAt?: string;
  title: string;
}

export interface CompletedEducationalResource {
  communities: ('bipoc' | 'lgbq' | 'tgnc')[];
  completionDate: Temporal.PlainDate | Temporal.PlainDateLike | string;
  credits: number;
  currentlyPublished: boolean;
  hours: number;
  id: string;
  name: string;
  /* organizations -- only Network */
  organizations?: {
    id: string;
    name: string;
  }[];
  publisher: string;
  resourceId: string;
  user: {
    fullName: string;
    id: string;
  };
  userId: string;
}

interface Certificate {
  birth_date: string | null;
  course_resource_id: string;
  credit_type: string;
  file: {
    url: string;
  };
  id: string;
  participant_category: string;
}

export interface CourseCollectionCompletion {
  collection: {
    canBeEvaluated: boolean;
    certificates: {
      birthDate: string | null;
      courseResourceId: string;
      createdAt: string;
      creditType: string;
      file: {
        url: string;
      };
      id: string;
      licenseNumber: string | null;
      licenseType: string | null;
      participantCategory: string;
      userId: string;
    }[];
    communities: string[];
    credits: string | null;
    description: string;
    evaluation: {
      answers: {
        agreement: {
          question: string;
          rating: number;
        }[];
        freeText: {
          feedback: string;
          practice_changes: string;
        };
        learningObjectives: {
          question: string;
          rating: number;
        }[];
        satisfaction: {
          question: string;
          rating: number;
        }[];
      }[];
      courseResourceId: string;
      id: string;
      userId: string;
    } | null;
    externalAccreditationReferences: {
      accrediting_organizations: {
        professionalGroup: string;
        professionalGroupAcronym: string;
      }[];
      vendorName: string;
      vendorResourceId: string;
    }[];
    financialDisclosures: {
      disclosureText: string;
    }[];
    hours: string | null;
    id: string;
    isAccredited: boolean;
    isNew: boolean;
    isPopular: boolean;
    learningObjectives: string[];
    level?: 'advanced' | 'beginner' | 'foundation' | 'intermediate';
    name: string;
    published: 'not_published' | 'pinned' | 'published';
    specialties: ('mental_health' | 'physical_health')[];
  };
  completedAt: string | null;
  completedCourseCount: number;
  courseCount: number;
  id: string;
  startedAt: string;
}

export interface EducationalResource {
  assignments: Bookmark[];
  author: string;
  bookmarkId: string | null;
  bookmarks: Bookmark[];
  ceCreditTypes: string[] | null;
  completedAt?: string | null;
  completionStatus: 'complete' | 'incomplete' | 'rated';
  contentMedium: string;
  courseCollections: EducationalResourceCourseCollection[];
  credits: string;
  creditsNumber?: string;
  description: string;
  financialDisclosures: string[];
  fullTitle: string;
  hours?: string | null;
  id: string;
  isNew: boolean;
  isPopular: boolean;
  isScormcloud: boolean;
  length: string;
  location?: string;
  organization: string;
  professionalGroups: string[];
  specialties: string[];
  tags: string;
  title: string;
  type: string;
  url: string;
}

interface EducationalResourceCourseCollection {
  id: string;
  is_accredited: boolean;
  is_completed: boolean;
  is_evaluated: boolean;
}

export interface NetworkDiversity {
  genderIdentity: {
    label: string;
    percentage: number;
  }[];
  language: {
    label: string;
    percentage: number;
  }[];
  raceEthnicity: {
    label: string;
    percentage: number;
  }[];
  sexualOrientation: {
    label: string;
    percentage: number;
  }[];
}

export interface NetworkEducationOverview {
  averageCourseScore?: number;
  ceCreditsEarned?: number;
  educationCompletedCount: number;
  educationHours: number;
}

export interface NetworkOrganizationMember {
  id: string;
  inclusivityPercentages: {
    bipoc: number;
    lgbq: number;
    tgnc: number;
  };
  name: string;
  onViolet: boolean;
  totalProviders: number;
  verifiedInclusive: boolean;
}

export interface NetworkOverview {
  networkDiversityPercentages: {
    bipoc: number;
    lgbq: number;
    tgnc: number;
  };
  networkInclusivityPercentages: {
    bipoc: number;
    lgbq: number;
    tgnc: number;
  };
  organizationName?: string;
  totalActiveNetworkProviders: number;
  totalNetworkOrganizations: number;
  totalNetworkProviders: number;
  totalNetworkProvidersWithBenchmarks: number;
  totalVioNetworkOrganizations: number;
  totalVioNetworkProviders: number;
}

export interface NetworkProvider {
  benchmarks: Benchmark[];
  id: string;
  name: string;
  onViolet: boolean;
  organizations: [
    {
      id: string;
      name: string;
    }
  ];
  specialties: string[];
}

export interface NetworkProvidersLocation {
  inclusivity: {
    bipoc: {
      percentage: number;
      total: number;
    };
    lgbq: {
      percentage: number;
      total: number;
    };
    tgnc: {
      percentage: number;
      total: number;
    };
  };
  name: string | null;
  state: string;
  totalProviders: number;
}

export interface NonClinicalExperience {
  bipoc: boolean;
  category?: 'advocacy' | 'leadership' | 'research' | 'teaching' | 'volunteer';
  createdAt: string;
  current: boolean;
  description?: string;
  endYear?: number;
  id: string;
  lgbq: boolean;
  organization: string;
  role: string;
  startYear?: number;
  tgnc: boolean;
  updatedAt: string;
  userId: string;
}

export interface Organization {
  id: string;
  name: string;
  permissions: string[];
}

export interface OrganizationAssignment {
  completedCount: number;
  endDate?: Temporal.PlainDate | Temporal.PlainDateLike | string;
  id: string;
  incompleteCount: number;
  isActive: boolean;
  percentageCompleted: number;
  resourceId: string;
  resourceName: string;
  resourceType: 'Course' | 'CourseCollection';
  startDate?: Temporal.PlainDate | Temporal.PlainDateLike | string;
  totalMemberCount: number;
  userId: string | null;
  users: {
    completed: boolean;
    completedAt: string | null;
    fullName: string;
    id: string;
  }[];
}

export interface OrganizationAssignmentDetails {
  completedCount: number;
  completionsOnboardedCount: number;
  completionsTotalCount: number;
  endDate?: Temporal.PlainDate | Temporal.PlainDateLike | string;
  hours: string;
  id: string;
  incompleteCount: number;
  incompleteOnboardedCount: number;
  isActive: boolean;
  percentageCompleted: number;
  percentageCompletedOnboarded: number;
  resourceId: string;
  resourceName: string;
  resourceType: 'Course' | 'CourseCollection';
  startDate?: Temporal.PlainDate | Temporal.PlainDateLike | string;
  totalMemberCount: number;
  totalOnboardedMemberCount: number;
  userId: string | null;
}

export interface OrganizationDemographics {
  genderIdentity: {
    label: string;
    percentage: number;
  }[];
  language: {
    label: string;
    percentage: number;
  }[];
  raceEthnicity: {
    label: string;
    percentage: number;
  }[];
  sexualOrientation: {
    label: string;
    percentage: number;
  }[];
}

export interface OrganizationEducationOverview {
  averageCourseScore: number;
  ceCreditsEarned: number;
  educationCompletedCount: number;
  educationHours: number;
  topCompletedCourses: OrganizationEducationTopResource[];
  topEngagedProviders: {
    educationCompletedCount: number;
    id: string;
    name: string;
  }[];
}

export interface OrganizationEducationTopResource {
  educationCompletedCount: number;
  id: string;
  title: string;
}

const generateShortCollection = (
  fields: APICourseCollections['data'][0]
): EducationalResourceCourseCollection => ({
  id: fields.id,
  is_accredited: fields.external_accreditation_references.length > 0,
  is_completed: fields.completed_course_count === fields.course_count,
  is_evaluated: fields.evaluation !== null
});

export interface OrganizationOverview {
  organizationName: string;
  providerDiversityPercentages: {
    bipoc: number;
    lgbq: number;
    tgnc: number;
  };
  providerInclusivityPercentages: {
    bipoc: number;
    lgbq: number;
    tgnc: number;
  };
  totalClinicalMembersInvited: number;
  totalClinicalMembersOnboarded: number;
  totalMembersInvited: number;
  totalMembersOnboarded: number;
  totalNonClinicalMembersInvited: number;
  totalNonClinicalMembersOnboarded: number;
  totalProvidersWithBenchmarks: number;
  userMetrics: {
    benchmarked: {
      bipoc: {
        percentage: number;
        total: number;
      };
      lgbq: {
        percentage: number;
        total: number;
      };
      percentage: number;
      tgnc: {
        percentage: number;
        total: number;
      };
      total: number;
    };
    clinical: {
      percentage: number;
      total: number;
    };
    nonClinical: {
      percentage: number;
      total: number;
    };
    onboarded: {
      clinical: number;
      clinicalPercentage: number;
      nonClinical: number;
      nonClinicalPercentage: number;
      percentage: number;
      total: number;
    };
    total: number;
  };
  verifiedInclusive: boolean;
  verifiedInclusiveCriteria: {
    percentageBenchmarked: number;
    percentageBenchmarkedMet: boolean;
    percentageBenchmarkedThreshold: number;
    percentageOnboardedMet: boolean;
    percentageOnboardedThreshold: number;
  };
}

export interface OrganizationUser {
  badges:
    | {
        assessedOn?: string;
        community?: string;
        dateEarned?: string;
        imageAltText: string;
        imageSrc: string;
        title: string;
      }[]
    | null;
  email: string;
  id: string;
  inviteStatus: 'already active' | 'already sent' | 'can send';
  isClinical: boolean;
  languages: string[];
  memberRole: string;
  membershipId?: string;
  name: string;
  onboardingStatus: OnboardingStatus;
  sendEmailReminderButtonState: SendEmailReminderButtonState;
  userInfo: APIUsersDashboardOrganizations['data'][0]['user']['user_info'];
}

export interface Pathway {
  accreditingOrganizations?: AccreditingOrganizations[]; // #SHOW only
  bookmarks: Bookmark[];
  completion: {
    completedAt: string | null;
    lastProgressAt: string | null;
    startedAt: string | null;
    totalCompletedResources: number;
  } | null;
  hours: string;
  id: string;
  image: {
    url: string | null;
  };
  isAccredited: boolean;
  isNew: boolean;
  isTrending: boolean;
  level: 'advanced' | 'foundation' | 'intermediate';
  longDescription: string | null;
  name: string;
  nextCourseId?: string | null;
  nextResourceId?: string | null;
  // #SHOW only
  resources: {
    communities?: ('BIPOC' | 'LGBQ' | 'TGNC')[]; // Collections
    completedAt: string | null;
    contentMedium?: string;
    credits?: string;
    description: string;
    hours: string | null;
    id: string;
    level?: 'advanced' | 'beginner' | 'foundation' | 'intermediate';
    name: string;
    position: number;
    resourceType: 'Course' | 'CourseCollection';
    totalCompletedCourses?: number;
    totalCourses?: number;
  }[];
  shortDescription: string | null;
  skills: string[];
  specialtyArea: ('mental_health' | 'non_clinical' | 'physical_health')[];
}

export const generateCollection = (
  fields: APICourseCollections['data'][0],
  user: User
): Collection => {
  fields.courses = fields.courses?.map(course => ({
    ...course,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
    course_collections: course.course_collections
      ? course.course_collections
      : [generateShortCollection(fields)]
  }));
  const hasEvaluationPermission = user.permissions.includes('evaluations.create');
  const accreditingOrganizations = fields.external_accreditation_references
    .map(reference =>
      reference.accrediting_organizations.map(
        org => camelizeObjectKeys(org) as AccreditingOrganizations
      )
    )
    .flat();
  return {
    accreditingOrganizations: uniqBy(
      accreditingOrganizations,
      reference => reference.professionalGroup
    ),
    bookmarks: fields.bookmarks,
    canBeEvaluated:
      hasEvaluationPermission &&
      !fields.evaluation &&
      fields.completion !== null &&
      fields.completion.completed_at !== null,
    certificates: fields.certificates,
    communities: fields.communities,
    completedAt: fields.completion?.completed_at,
    completionCount: fields.completed_course_count,
    courses: fields.courses?.map(generateEducationalResource) ?? [],
    credits:
      fields.credits !== null
        ? `${Number(fields.credits)} credit${Number(fields.credits) === 1 ? '' : 's'}`
        : '',
    description: fields.description,
    duration: Number(fields.hours),
    evaluation: fields.evaluation,
    externalAccreditationReferences: fields.external_accreditation_references,
    hours: fields.hours,
    id: fields.id,
    isAccredited: fields.external_accreditation_references.length > 0,
    isNew: fields.is_new,
    isPopular: fields.is_popular,
    level: fields.level,
    progress: (fields.completed_course_count / fields.course_count) * 100,
    published: fields.published,
    specialties: fields.specialties,
    startedAt: fields.completion?.started_at,
    title: fields.name
  };
};

export const generateCollectionCompletion = (
  fields: APICourseCollectionCompletions['data'][0],
  user: User
): CourseCollectionCompletion => {
  const completion = camelizeObjectKeys(fields) as CourseCollectionCompletion;
  const hasEvaluationPermission = user.permissions.includes('evaluations.create');
  completion.collection.canBeEvaluated =
    hasEvaluationPermission &&
    completion.collection.evaluation === null &&
    completion.completedAt !== null;
  return completion;
};

enum SendEmailReminderButtonState {
  Hidden,
  Sending,
  Sent,
  Unsent
}

export const generateEducationalResource = (fields: Course): EducationalResource => ({
  assignments: fields.bookmarks.filter(bookmark => bookmark.assigned_by_name !== null),
  author: fields.author,
  bookmarkId:
    fields.bookmarks.find(
      bookmark => bookmark.user_id !== null && bookmark.assigned_by_name === null
    )?.id ?? null,
  bookmarks: fields.bookmarks,
  ceCreditTypes: fields.ce_credit_types,
  completedAt: fields.completion?.completed_at,
  completionStatus: fields.rated ? 'rated' : fields.completion !== null ? 'complete' : 'incomplete',
  contentMedium: fields.content_medium,
  courseCollections: fields.course_collections,
  credits:
    fields.ce_credits === undefined
      ? 'N/A'
      : `${Number(fields.ce_credits)} credit${Number(fields.ce_credits) === 1 ? '' : 's'}`,
  creditsNumber: fields.ce_credits,
  description: fields.description,
  financialDisclosures: fields.financial_disclosures.map(disclosure => disclosure.disclosure_text),
  fullTitle: fields.full_title,
  hours: fields.hours,
  id: fields.id,
  isNew: fields.is_new,
  isPopular: fields.is_popular,
  isScormcloud: fields.is_scormcloud,
  length:
    fields.hours === undefined
      ? 'N/A'
      : `${Number(fields.hours)} hour${Number(fields.hours) === 1 ? '' : 's'}`,
  organization: fields.organization_name,
  professionalGroups: fields.professional_groups,
  specialties: fields.specialties,
  tags: [
    ...(fields.bipoc_communities.length === 0 ? [] : ['BIPOC']),
    ...(fields.lgbq_communities.length === 0 ? [] : ['LGBQ']),
    ...(fields.tgnc_communities.length === 0 ? [] : ['TGNC']),
    ...(fields.other_communities.length === 0 ? [] : ['Other'])
  ].join('/'),
  title: fields.short_title,
  type: fields.format_category,
  url: fields.link
});

export const generateNetworkDiversity = (
  fields: APINetworksProvidersDiversity['data']
): NetworkDiversity => camelizeObjectKeys(fields) as NetworkDiversity;

export const generateNetworkEducation = (
  fields: APINetworksEducations['data'][0]
): CompletedEducationalResource => {
  const completionDate = Temporal.PlainDate.from(
    fields.completed_at[fields.completed_at.length - 1] === 'Z'
      ? fields.completed_at.slice(0, -1)
      : fields.completed_at
  );
  return camelizeObjectKeys({ ...fields, completionDate }) as CompletedEducationalResource;
};

export const generateNetworkEducationOverview = (
  fields: APINetworksEducationsOverview['data']
): NetworkEducationOverview => camelizeObjectKeys(fields) as NetworkEducationOverview;

export const generateNetworkOrgMembers = (
  fields: APINetworksOrganizationMembers['data'][0]
): NetworkOrganizationMember => camelizeObjectKeys(fields) as NetworkOrganizationMember;

export const generateNetworkOverview = (fields: APINetworksOverview['data']): NetworkOverview =>
  camelizeObjectKeys(fields) as NetworkOverview;

export const generateNetworkProviders = (
  fields: APINetworksProviders['data'][0]
): NetworkProvider => camelizeObjectKeys(fields) as NetworkProvider;

export const generateNetworkProvidersLocations = (
  fields: APINetworksProvidersLocations['data'][0]
): NetworkProvidersLocation => camelizeObjectKeys(fields as object) as NetworkProvidersLocation;

export const generateNonClinicalExperience = (
  fields: APINonClinicalExperience['data'][0]
): NonClinicalExperience => camelizeObjectKeys(fields) as NonClinicalExperience;

export const generateNonClinicalExperiences = (
  fields: APINonClinicalExperience['data']
): NonClinicalExperience[] => camelizeObjectKeys(fields) as NonClinicalExperience[];

export const generateOrganizationAssignment = (
  fields: APIOrganizationsOrganizationIdAssignments['data'][0]
): OrganizationAssignment => {
  const startDate =
    fields.start_date !== null
      ? Temporal.PlainDate.from(
          fields.start_date[fields.start_date.length - 1] === 'Z'
            ? fields.start_date.slice(0, -1)
            : fields.start_date
        )
      : undefined;
  const endDate =
    fields.end_date !== null
      ? Temporal.PlainDate.from(
          fields.end_date[fields.end_date.length - 1] === 'Z'
            ? fields.end_date.slice(0, -1)
            : fields.end_date
        )
      : undefined;
  return camelizeObjectKeys({ ...fields, endDate, startDate }) as OrganizationAssignment;
};

export const generateOrganizationAssignmentDetails = (
  fields: APIOrganizationsOrganizationIdAssignmentId['data']
): OrganizationAssignmentDetails => {
  const startDate =
    fields.start_date !== null
      ? Temporal.PlainDate.from(
          fields.start_date[fields.start_date.length - 1] === 'Z'
            ? fields.start_date.slice(0, -1)
            : fields.start_date
        )
      : undefined;
  const endDate =
    fields.end_date !== null
      ? Temporal.PlainDate.from(
          fields.end_date[fields.end_date.length - 1] === 'Z'
            ? fields.end_date.slice(0, -1)
            : fields.end_date
        )
      : undefined;
  return camelizeObjectKeys({ ...fields, endDate, startDate }) as OrganizationAssignmentDetails;
};

export const generateOrganizationAssignmentUser = (
  fields: APIOrganizationsOrganizationIdAssignmentIdUsers['data'][0]
): AssignmentUser => {
  const completedAt =
    fields.completed_at !== null
      ? Temporal.PlainDate.from(
          fields.completed_at[fields.completed_at.length - 1] === 'Z'
            ? fields.completed_at.slice(0, -1)
            : fields.completed_at
        )
      : undefined;
  return camelizeObjectKeys({ ...fields, completedAt }) as AssignmentUser;
};

export const generateOrganizationDemographics = (
  fields: APIOrganizationDemographics['data']
): OrganizationDemographics => camelizeObjectKeys(fields) as OrganizationDemographics;

export const generateOrganizationEducation = (
  fields: APIUsersDashboardOrganizationsEducations['data'][0]
): CompletedEducationalResource => {
  const completionDate = Temporal.PlainDate.from(
    fields.completed_at[fields.completed_at.length - 1] === 'Z'
      ? fields.completed_at.slice(0, -1)
      : fields.completed_at
  );
  return camelizeObjectKeys({ ...fields, completionDate }) as CompletedEducationalResource;
};

export const generateOrganizationEducationOverview = (
  fields: APIOrganizationsOrganizationIdEducationsOverview['data']
): OrganizationEducationOverview => camelizeObjectKeys(fields) as OrganizationEducationOverview;

export const generateOrganizationEducationTopResources = (
  fields: APIOrganizationsOrganizationIdEducationsTopResources['data']
): OrganizationEducationTopResource[] =>
  camelizeObjectKeys(fields) as OrganizationEducationTopResource[];

export const generateOrganizationOverview = (
  fields: APIOrganizationOverview['data']
): OrganizationOverview => camelizeObjectKeys(fields) as OrganizationOverview;

export const generatePathway = (fields: APIPathwaysShow['data']): Pathway =>
  camelizeObjectKeys(fields) as Pathway;

export const generatePathwayCompletion = (
  fields: APIPathwaysStart['data']
): Pathway['completion'] =>
  fields !== null ? (camelizeObjectKeys(fields) as Pathway['completion']) : null;

export enum OnboardingStatus {
  Complete,
  Incomplete
}

/* Disabled to keep OnboardingStatus before usage */
// eslint-disable-next-line sort-exports/sort-exports
export const generateOrganizationUser = (
  fields: APIUsersDashboardOrganizations['data'][0]
): OrganizationUser => {
  const { id, user } = fields;
  const sendEmailReminderButtonState =
    user.invite_status === 'can send'
      ? SendEmailReminderButtonState.Unsent
      : user.invite_status === 'already sent'
        ? SendEmailReminderButtonState.Sent
        : SendEmailReminderButtonState.Hidden;

  const sortBadges = (
    badge1: (typeof fields.user.user_info.scores)[0],
    badge2: (typeof fields.user.user_info.scores)[0]
  ) => {
    if (badge1.score_type === 'bipoc') return -1;
    if (badge1.score_type === 'tgnc') return 1;
    if (badge1.score_type === 'lgbq') {
      if (badge2.score_type === 'bipoc') return 1;
      if (badge2.score_type === 'tgnc') return -1;
    }
    return 0;
  };

  return {
    badges: !user.completed_onboarding
      ? null
      : user.user_info.scores.sort(sortBadges).map(badge => {
          const {
            day: dateEarnedDay,
            month: dateEarnedMonth,
            year: dateEarnedYear
          } = Temporal.PlainDate.from(
            badge.earned_at[badge.earned_at.length - 1] === 'Z'
              ? badge.earned_at.slice(0, -1)
              : badge.earned_at
          );
          const formattedDateEarned = `${dateEarnedMonth
            .toString()
            .padStart(2, '0')}/${dateEarnedDay.toString().padStart(2, '0')}/${dateEarnedYear}`;

          const badgeIsNotYetAwarded = badge.score_level === 'placeholder';

          if (badgeIsNotYetAwarded) {
            return {
              assessedOn: formattedDateEarned,
              imageAltText: 'Disabled badge',
              imageSrc: notYetAwardedBadgeImageSrc,
              title: 'Benchmark not yet earned'
            };
          }

          const community = badge.score_type.toUpperCase();
          const level = `${badge.score_level[0].toUpperCase()}${badge.score_level.slice(1)}`;

          return {
            community,
            dateEarned: formattedDateEarned,
            imageAltText: `${community} ${level} badge`,
            imageSrc: `${process.env.REACT_APP_BASE_PATH}${badge.image.url}`,
            title: `${community} ${level} Benchmark`
          };
        }),
    email: user.email,
    id: user.id,
    inviteStatus: user.invite_status,
    isClinical: user.is_clinical,
    languages: user.user_info.languages,
    memberRole: fields.member_role,
    membershipId: id,
    name: user.full_name,
    onboardingStatus: user.completed_onboarding
      ? OnboardingStatus.Complete
      : OnboardingStatus.Incomplete,
    sendEmailReminderButtonState,
    userInfo: user.user_info
  };
};

export const generateUser = (fields: User, organizationId: string): OrganizationUser => {
  const user = { ...fields };
  const sendEmailReminderButtonState =
    user.invite_status === 'can send'
      ? SendEmailReminderButtonState.Unsent
      : user.invite_status === 'already sent'
        ? SendEmailReminderButtonState.Sent
        : SendEmailReminderButtonState.Hidden;

  return {
    badges: !user.completed_onboarding
      ? null
      : user.active_scores.map(badge => {
          const {
            day: dateEarnedDay,
            month: dateEarnedMonth,
            year: dateEarnedYear
          } = Temporal.PlainDate.from(
            badge.earned_at[badge.earned_at.length - 1] === 'Z'
              ? badge.earned_at.slice(0, -1)
              : badge.earned_at
          );
          const formattedDateEarned = `${dateEarnedMonth
            .toString()
            .padStart(2, '0')}/${dateEarnedDay.toString().padStart(2, '0')}/${dateEarnedYear}`;

          const badgeIsNotYetAwarded = badge.score_level === 'placeholder';

          if (badgeIsNotYetAwarded) {
            return {
              assessedOn: formattedDateEarned,
              community: badge.score_type.toUpperCase(),
              imageAltText: 'Disabled badge',
              imageSrc: notYetAwardedBadgeImageSrc,
              title: 'Benchmark not yet earned'
            };
          }

          const community = badge.score_type.toUpperCase();
          const level = `${badge.score_level[0].toUpperCase()}${badge.score_level.slice(1)}`;

          return {
            community,
            dateEarned: formattedDateEarned,
            imageAltText: `${community} ${level} badge`,
            imageSrc: `${process.env.REACT_APP_BASE_PATH}${badge.image.url}`,
            title: `${community} ${level} Benchmark`
          };
        }),
    email: user.email,
    id: user.id,
    inviteStatus: user.invite_status,
    isClinical: user.is_clinical,
    languages: user.language_proficiencies.map(language => language.language),
    memberRole:
      user.organization_memberships.find(
        membership => membership.organization_id === organizationId
      )?.member_role ?? 'user',
    membershipId: user.organization_memberships.find(
      membership => membership.organization_id === organizationId
    )?.id,
    name: `${user.first_name} ${user.last_name}`,
    onboardingStatus: user.completed_onboarding
      ? OnboardingStatus.Complete
      : OnboardingStatus.Incomplete,
    sendEmailReminderButtonState,
    userInfo: user.user_info
  };
};
