import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { prepareLocationsParam } from 'src/utils/prepareLocationParms';
import { useDebounce } from 'src/utils/useDebounce';

import { generateNetworkProvidersLocations, NetworkProvidersLocation } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

export interface LocationFilter {
  city: string;
  county: string;
  state: string;
  zip: string;
}

export interface NetworkLocationsFilters {
  'benchmark_communities[]'?: string[];
  locations?: AtLeastOne<LocationFilter>[] | string; // stringified array of objects for API or array of objects for frontend
  order_by?: {
    column: 'provider_count' | 'state';
    dir: 'asc' | 'desc';
  };
  org_is_on_violet?: boolean;
  'organizations[]'?: string[];
  page?: number;
  per_page?: number;
  'specialties[]'?: string[];
  'states[]'?: string[];
  verified_inclusive?: boolean;
  view?: 'city' | 'county' | 'state' | 'zip';
}

interface UseGetNetworkLocations {
  (filters: NetworkLocationsFilters): {
    isFetching: boolean;
    networkLocations: NetworkProvidersLocation[] | undefined;
    networkLocationsTotalPages: number;
    updateNetworkLocationFilters: (newFilters: Partial<NetworkLocationsFilters>) => void;
  };
}

const useGetNetworkLocations: UseGetNetworkLocations = filters => {
  const { getRequest, reportError } = useApiRequest();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [networkLocations, setNetworkLocations] = useState<NetworkProvidersLocation[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [previousFilters, setPreviousFilters] = useState(filters);
  const [networkLocationsTotalPages, setNetworkLocationsTotalPages] = useState(1);

  const fetchNetworkLocations = useCallback(
    async (filters: NetworkLocationsFilters) => {
      setIsFetching(true);

      /* TODO: considerations
        - opt A) get the BE working to interpret locations as an array of objects correctly
        - opt B) refactor filtersToParams to support stringifing arrays of objects in specific cases
      */
      const paramsToSend = { ...filters };
      paramsToSend.locations = prepareLocationsParam(paramsToSend.locations);
      const params = filtersToParams(paramsToSend);

      const url: RequestInfo = `${
        process.env.REACT_APP_API_BASE_PATH
      }/networks/providers/locations${params ? `?${params}` : ''}`;

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APINetworksProvidersLocations['data'];
          meta?: APINetworksProvidersLocations['meta'];
        };
        setNetworkLocations(
          data !== undefined ? data.map(fields => generateNetworkProvidersLocations(fields)) : []
        );
        setNetworkLocationsTotalPages(meta !== undefined ? meta.total_pages : 1);
      } catch (error) {
        reportError(error);
      }

      setIsFetching(false);
    },
    [getRequest, reportError]
  );

  const debouncedFetchNetworkLocations = useDebounce(fetchNetworkLocations, 200);

  useEffect(() => {
    if (!isFirstRender) return;
    debouncedFetchNetworkLocations(filters);
    setIsFirstRender(false);
  }, [isFirstRender, filters, debouncedFetchNetworkLocations]);

  const updateNetworkLocationFilters = (newFilters: Partial<NetworkLocationsFilters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof NetworkLocationsFilters] === undefined
        ? delete newFilters[key as keyof NetworkLocationsFilters]
        : {}
    );
    if (isEqual(newFilters, previousFilters)) return;

    setPreviousFilters({ ...newFilters });
    debouncedFetchNetworkLocations(newFilters);
  };

  return {
    isFetching,
    networkLocations,
    networkLocationsTotalPages,
    updateNetworkLocationFilters
  };
};

export default useGetNetworkLocations;
